<template>
  <sign-page
    title-text="医院职称聘任情况"
    :request="request"
    :column-list="columnList"
    table-size="large"
    :tableActionsFixed="true"
    :titleMenus="[]"
    @tableAction="tableAction"
    :tableActions="tableActions"
    :need-data-file="true">
    <fm-modal v-model="modal" width="80%" title="个人上岗资格明细">
      <div style="min-height:20rem" v-if="modal">
        <div class="worker-card-list" v-if="chooseData && chooseData.matchDatas && chooseData.matchDatas.length > 0">
          <WorkerPromotion :current="chooseData.matchDatas[0].workerJobTitle" :worker="chooseData.matchDatas[0].worker" :dataList="chooseData.matchDatas"></WorkerPromotion>
        </div>
        <div class="worker-card-list-null" v-else>
          <img src="/static/images/null.png" style="width: 40px;margin-bottom:20px;"/>
          <div>暂无数据</div>
        </div>
      </div>
    </fm-modal>
  </sign-page>
</template>

<script>
import {
  jobTitleRequest as request
} from '../../api'

import WorkerPromotion from './worker/workerPromotion'

export default {
  components: {
    WorkerPromotion
  },
  methods: {
    search () {
      this.name = this.searchname
    },
    async loadData () {
      let dataList = []
      let datas = []
      if (this.$authFunsProxy.get) {
        dataList = await request.getCurrent()
        datas = await request.getMatch()
      } else if (this.$authFunsProxy.getMy) {
        dataList = await request.getCurrentMy()
        datas = await request.getMatchMy()
      } else if (this.$authFunsProxy.getMyOrg) {
        dataList = await request.getCurrentMyOrg()
        datas = await request.getMatchMyOrg()
      } else if (this.$authFunsProxy.getMyOrgContainChild) {
        dataList = await request.getCurrentMyOrgContainChild()
        datas = await request.getMatchMyOrgContainChild()
      }
      let allData = []
      datas.forEach(v => {
        allData = allData.concat(v.queue)
      })
      dataList.forEach(v => {
        v.matchDatas = allData.filter(v1 => v1.worker.id === v.workerId && v1.workerJobTitle && v1.workerJobTitle.id === v.id)
      })
      return dataList
    },
    tableAction (parm) {
      this.chooseData = parm.data
      this.modal = true
    }
  },
  computed: {
    showToolBox () {
      return ['table-export', 'table-config'].filter(v => this.$authFunsProxy[v]).map(v => v.replace('table-', ''))
    },
    tableActions () {
      return [{
        key: 'detail',
        label: '明细'
      }]
    },
    columnList () {
      return [{
        title: '员工姓名',
        sort: true,
        fixed: 'left',
        field: 'workerName'
      },
      {
        title: '岗位类型',
        sort: true,
        field: 'jobTitleType',
        filters: this.$store.getters.selectDatas['job_title_type'].map(v => {
          return {
            label: v.label,
            value: v.label
          }
        })
      },
      {
        title: '岗位层级',
        sort: true,
        field: 'topLevel',
        filters: [{key: '初级', label: '初级'}, {key: '中级', label: '中级'}, {key: '高级', label: '高级'}].map(v => {
          return {
            label: v.label,
            value: v.label
          }
        })
      },
      {
        title: '职称级别',
        sort: true,
        field: 'mLevel',
        filters: [{key: '员级', label: '员级'}, {key: '助理级', label: '助理级'}, {key: '中级', label: '中级'}, {key: '副高级', label: '副高级'}, {key: '正高级', label: '正高级'}].map(v => {
          return {
            label: v.label,
            value: v.label
          }
        })
      },
      {
        title: '岗位等级',
        sort: true,
        field: 'jobTitleTitle',
        filterMethod (parm) {
          return parm.row.jobTitleTitle === parm.value
        }
      },
      {
        title: '本岗累积工作年限',
        filterRange: true,
        sort: true,
        dataType: Number,
        field: 'diffYear'
      },
      {
        title: '当前岗位上岗时间',
        filterRange: true,
        dataType: Date,
        width: 250,
        sort: true,
        field: 'hireStartDate',
        render: (h, rowData) => {
          return h('div', rowData.hireStartDate ? rowData.hireStartDate.slice(0, 10) : '')
        },
        form: (rowData) => {
          return rowData.hireStartDate ? rowData.hireStartDate.slice(0, 10) : ''
        }
      },
      {
        title: '参加工作时间',
        sort: true,
        filterRange: true,
        dataType: Date,
        width: 250,
        field: 'workerCorrectionDate',
        render: (h, rowData) => {
          return h('div', rowData.workerCorrectionDate ? rowData.workerCorrectionDate.slice(0, 10) : '')
        },
        form: (rowData) => {
          return rowData.workerCorrectionDate ? rowData.workerCorrectionDate.slice(0, 10) : ''
        }
      },
      {
        title: '入职单位时间',
        sort: true,
        filterRange: true,
        dataType: Date,
        width: 250,
        field: 'workerEntryDate',
        render: (h, rowData) => {
          return h('div', rowData.workerEntryDate ? rowData.workerEntryDate.slice(0, 10) : '')
        },
        form: (rowData) => {
          return rowData.workerEntryDate ? rowData.workerEntryDate.slice(0, 10) : ''
        }
      }]
    }
  },
  data () {
    return {
      request: {
        get: this.loadData
      },
      modal: false,
      chooseData: null
    }
  }
}
</script>

<style lang="less" scoped>
.worker-card-list {
  display: flex;
  width: 100%;
  overflow-x:auto;
  .worker-card-item {
    margin-right: 1rem;
  }
}
.modal-s {
  position: absolute;
  top: 6px;
  right: 60px;
  display: flex;
  justify-content: flex-end;
}
.worker-card-list-null {
  width: 100%;
  height: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
